import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { storeInstance } from './store/configureStore';
import { ErrorBoundary } from '@s7/base';
import { ErrorFallback } from './fsd-structure/shared/ui/ErrorFallback'


ReactDOM.render(
    <HelmetProvider>
        <Provider store={storeInstance}>
            <ErrorBoundary renderFallback={ErrorFallback}>
                <App />
            </ErrorBoundary>
        </Provider>
    </HelmetProvider>
    , document.getElementById('root'));
// registerServiceWorker();
