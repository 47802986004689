import pushGA4 from '../utils/pushGA4';
import pushYM from '../utils/pushYM';
export var analyticsAlertShow = function (type) {
    pushGA4('show_alert', 'portal', 'main', {
        step: 'alert',
        type: type,
    });
    pushYM({
        goalParams: {
            event_name: 'show_alert',
            application: 'portal',
            flow: 'main',
            step: 'alert',
            type: type,
        },
    });
};
export var analyticsAlertClose = function (type) {
    pushGA4('click_cta', 'portal', 'main', {
        step: 'alert',
        type: type,
        result: 'close_alert',
    });
    pushYM({
        goalParams: {
            event_name: 'click_cta',
            application: 'portal',
            flow: 'main',
            step: 'alert',
            type: type,
            result: 'close_alert',
        },
    });
};
export var analyticsAlertClick = function (type) {
    pushGA4('click_cta', 'portal', 'main', {
        step: 'alert',
        type: type,
        result: 'click_alert',
    });
    pushYM({
        goalParams: {
            event_name: 'click_cta',
            application: 'portal',
            flow: 'main',
            step: 'alert',
            type: type,
            result: 'click_alert',
        },
    });
};
